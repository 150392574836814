<template>
    <div class="score-manage">
        <el-form class="page-search" :inline="true" label-width="100px">
            <div class="left">
                <div class="show">
                    <el-form-item label="">
                        <el-select
                            v-model="searchForm.employeeId"
                            clearable
                            placeholder="员工"
                            filterable
                        >
                            <el-option
                                v-for="item in employeeOptions"
                                :key="item.employeeId"
                                :label="item.employeeName"
                                :value="item.employeeId"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select
                            v-model="searchForm.orderFrom"
                            clearable
                            placeholder="积分类型"
                        >
                            <el-option
                                v-for="item in scoreTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.fromTitle"
                            clearable
                            placeholder="积分来源"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-input
                            v-model="searchForm.remark"
                            clearable
                            placeholder="备注"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-date-picker
                            v-model="dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="获取开始时间"
                            end-placeholder="获取结束时间"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            :clearable="true"
                        >
                        </el-date-picker>
                    </el-form-item>
                </div>
                <div v-if="conceal" class="hidden">
                    <el-form-item label="">
                        <el-date-picker
                            v-model="createTimeRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="创建开始时间"
                            end-placeholder="创建结束时间"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            clearable
                        >
                        </el-date-picker>
                    </el-form-item>
                </div>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="more" @click="conceal = !conceal">
                        <span v-show="!conceal">展开</span>
                        <span v-show="conceal">收起</span>
                        <i v-show="!conceal" class="el-icon-arrow-down"></i>
                        <i v-show="conceal" class="el-icon-arrow-up"></i>
                    </div>
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="querydata"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span>
                    <i class="el-icon-plus" @click="handleAdd">新增</i>
                </span>
            </div>
            <gb-table
                :table="table"
                table-height="400"
                :is-show-pagination="true"
                @pageChange="pageChange"
                @sizeChange="sizeChange"
            />
        </div>
        <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
            <div slot="title" class="header-title">
                <span
                    class="title-name"
                    style="font-size: 0.18rem; font-weight: 500; color: #606266;"
                >积分新增</span>
            </div>
            <el-form
                ref="addForm"
                :model="addForm"
                class="addScoreForm"
                inline="true"
                label-width="80px"
                :rules="addFormRule"
            >
                <div class="left">
                    <el-form-item label="类型" prop="orderFrom">
                        <el-select
                            v-model="addForm.orderFrom"
                            placeholder="积分类型"
                        >
                            <el-option
                                v-for="item in scoreTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="积分来源" prop="fromTitle">
                        <el-select
                            v-model="addForm.fromTitle"
                            filterable
                            remote
                            allow-create
                            clearable
                            :remote-method="remoteMethod"
                            default-first-option
                            placeholder="积分来源"
                            @change="handleCourseChange"
                        >
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="积分" required>
                        <el-input-number
                            v-model="score"
                            :step="0.5"
                            :min="0.5"
                            :max="10"
                            @change="handleChange"
                        ></el-input-number>
                    </el-form-item>
                    <el-form-item label="日期" prop="scoreTime">
                        <el-date-picker
                            v-model="addForm.scoreTime"
                            type="date"
                            placeholder="选择日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            v-model.trim="addForm.remark"
                            type="textarea"
                            maxlength="100"
                            show-word-limit
                            clearable
                            placeholder="备注"
                        ></el-input>
                    </el-form-item>
                </div>
                <div class="gb-table right">
                    <el-form inline="true">
                        <el-form-item label="">
                            <!-- <el-select
                                v-model="groupId"
                                filterable
                                placeholder="请选择组织"
                                @change="queryPeople"
                                clearable
                            >
                                <el-option
                                    v-for="item in deptOptions"
                                    :key="item.id"
                                    :label="item.groupName"
                                    :value="item.id"
                                >
                                </el-option>
                            </el-select> -->
                            <department
                                ref="dept"
                                v-model="groupId"
                                placeholder="请选择组织"
                                api-name="getAllDepts"
                                :is-cascader="true"
                                value-filed="id"
                                label-filed="groupName"
                                @change="queryPeople"
                            />
                        </el-form-item>
                        <el-form-item label="">
                            <el-input
                                v-model.trim="employeeName"
                                placeholder="姓名"
                                clearable
                                @input="queryPeople"
                                @clear="queryPeople"
                            ></el-input>
                        </el-form-item>
                        <el-button
                            type="primary"
                            size="small"
                            @click="queryPeople"
                        >
                            查询
                        </el-button>
                        <el-button @click="resetPeople">重置</el-button>
                    </el-form>
                    <el-table
                        ref="multipleTable"
                        :border="false"
                        height="400"
                        :row-key="getRowKey"
                        :data="addTable.data"
                        tooltip-effect="dark"
                        style="width: 100%;"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column
                            type="selection"
                            :reserve-selection="true"
                            width="55"
                        >
                        </el-table-column>
                        <el-table-column
                            type="index"
                            label="序号"
                            min-width="55"
                            align="center"
                        >
                            <template slot-scope="scope">
                                <span>{{
                                    (addTable.currentPage - 1) *
                                        addTable.pageSize +
                                        scope.$index +
                                        1
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="员工姓名"
                            min-width="100"
                            align="center"
                        >
                            <template slot-scope="scope">
                                {{
                                    scope.row.employeeName
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="groupName"
                            min-width="150"
                            label="组织"
                            align="center"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            prop="realScore"
                            label="积分"
                            align="center"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    v-if="scope.row.edit"
                                    v-model="scope.row.realScore"
                                    :step="0.5"
                                    style="width: 130px;"
                                    :min="0.5"
                                    :max="10"
                                ></el-input-number>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :current-page="addTable.currentPage"
                        :total="addTable.total"
                        :page-size="addTable.pageSize"
                        @current-change="addTablePageChange"
                    >
                    </el-pagination>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center;">
                <el-button
                    type="primary"
                    @click="handeleConfirm"
                >
                    确 定
                </el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog
            :visible.sync="editFormVisible"
            width="700px"
            :title="'积分编辑'"
            class="editScoreDialog"
            :close-on-click-modal="false"
        >
            <el-row>
                <el-col :span="12"> 员工：{{ editForm.studentName }} </el-col>
                <el-col :span="12"> 积分来源：{{ editForm.fromTitle }} </el-col>
            </el-row>
            <el-form
                ref="editForm"
                :model="editForm"
                inline
                label-width="120px"
                :rules="editFormRules"
            >
                <el-form-item label="类型" prop="orderFrom">
                    <el-select
                        v-model="editForm.orderFrom"
                        placeholder="积分类型"
                    >
                        <el-option
                            v-for="item in scoreTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="预计获得积分" prop="orderScore">
                    <el-input-number
                        v-model="editForm.orderScore"
                        :step="0.5"
                        :min="0.5"
                        :max="10"
                        @change="handleChange"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="获得积分日期" prop="scoreTime">
                    <el-date-picker
                        v-model="editForm.scoreTime"
                        type="date"
                        placeholder="选择日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="实际获得积分" prop="realScore">
                    <el-input-number
                        v-model="editForm.realScore"
                        :step="0.5"
                        :min="0.5"
                        :max="10"
                        @change="handleChange"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        v-model.trim="editForm.remark"
                        type="textarea"
                        maxlength="100"
                        show-word-limit
                        clearable
                        placeholder="备注"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: center;">
                <el-button
                    :loading="loading"
                    type="primary"
                    @click="comfirmEdit"
                >
                    确 定
                </el-button>
                <el-button @click="editFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import oaSvc from '@/services/oa'
import department from '@/components/Department'
export default {
    name: 'ScoreManage',
    components: { department },
    data () {
        return {
            table: {
                total: 0,
                currentPage: 1,
                pageSize: 20,
                data: [],
                column: [
                    { prop: 'studentName', label: '员工', minWidth: '100' },
                    {
                        prop: 'orderFrom',
                        label: '类型',
                        minWidth: '100',
                        formatter: data => {
                            return this.scoreTypes.filter(
                                item => item.value === data.orderFrom
                            )[0].label
                        }
                    },
                    {
                        prop: 'fromTitle',
                        label: '积分来源',
                        minWidth: '100',
                        showTip: true
                    },
                    {
                        prop: 'orderScore',
                        label: '预计获得积分',
                        minWidth: '50'
                    },
                    {
                        prop: 'realScore',
                        label: '实际获得积分',
                        minWidth: '50'
                    },
                    {
                        prop: 'scoreTime',
                        label: '积分获得时间',
                        minWidth: '100',
                        formatter: row => {
                            return row.scoreTime
                                ? this.$moment(row.scoreTime).format(
                                    'YYYY-MM-DD'
                                )
                                : ''
                        }
                    },
                    {
                        prop: 'scoreTime',
                        label: '创建时间',
                        minWidth: '100',
                        formatter: row => {
                            return row.createTime
                                ? this.$moment(row.createTime).format(
                                    'YYYY-MM-DD'
                                )
                                : ''
                        }
                    },
                    { prop: 'remark', label: '备注', minWidth: '100' }
                ],
                operator: [
                    { text: '编辑', fun: this.handleEdit },
                    { text: '删除', fun: this.handleDel }
                ],
                operatorConfig: {
                    minWidth: '80',
                    fixed: false
                }
            },
            scoreTypes: [
                { value: 0, label: '培训课程' },
                { value: 1, label: '比赛' },
                { value: 2, label: '奖惩' }
            ],
            conceal: false,
            searchForm: {
                fromTitle: '',
                remark: '',
                orderFrom: '',
                employeeId: ''
            },
            dateRange: [],
            addForm: {
                fromTitle: '',
                remark: '',
                orderFrom: 0,
                scoreTime: ''
            },
            addFormRule: {
                orderFrom: [{ required: true, trigger: 'change' }],
                fromTitle: [
                    {
                        required: true,
                        message: '请填写积分来源',
                        trigger: 'change'
                    }
                ],
                scoreTime: [
                    {
                        required: true,
                        message: '请选择日期',
                        trigger: 'change,blur'
                    }
                ]
            },
            addTable: {
                total: 0,
                currentPage: 1,
                pageSize: 20,
                data: [],
                firstColumn: { type: 'selection' },
                column: [
                    { prop: 'employeeName', label: '员工' },
                    { prop: 'groupName', label: '组织' },
                    { prop: 'remark', label: '积分' }
                ]
            },
            dialogVisible: false,
            score: 2,
            employeeName: '',
            groupId: '',
            selections: [],
            createTimeRange: [], // 创建日期
            employeeOptions: [], // 员工数据
            options: [], // 课程表搜索出的结果
            // list: [],
            deptOptions: [],
            /** ----编辑  ----- */
            editFormVisible: false,
            editForm: {
                studentName: '',
                scoreTime: '',
                fromTitle: '',
                orderScore: '',
                realScore: '',
                orderFromMeaning: ''
            },
            editFormRules: {
                orderFrom: [{ required: true, trigger: 'change' }],
                orderScore: [
                    {
                        required: true,
                        message: '请填写预计获得积分',
                        trigger: 'change'
                    }
                ],
                realScore: [
                    {
                        required: true,
                        message: '请填写实际获得积分',
                        trigger: 'change'
                    }
                ],
                scoreTime: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                ]
            }
        }
    },
    computed: {
        selectFrom () {
            return this.dateRange && this.dateRange[0]
                ? this.dateRange[0] + ' 00:00:00'
                : null
        },
        selectTo () {
            return this.dateRange && this.dateRange[1]
                ? this.dateRange[1] + ' 23:59:59'
                : null
        },
        createFrom () {
            return this.createTimeRange && this.createTimeRange[0]
                ? this.createTimeRange[0] + ' 00:00:00'
                : null
        },
        createTo () {
            return this.createTimeRange && this.createTimeRange[1]
                ? this.createTimeRange[1] + ' 23:59:59'
                : null
        }
    },
    watch: {
        dialogVisible (newValue) {
            if (newValue) {
                if (this.$refs.addForm && this.$refs.dept && this.$refs.multipleTable) {
                    this.$refs.addForm.resetFields()
                    this.$refs.dept.cascaderValue = ''
                    this.$refs.dept.currentItem = {}
                    this.groupId = ''
                    this.addForm.remark = ''
                    this.addForm.scoreTime = ''
                    this.score = 2
                    this.selections = []
                    this.options = []
                    this.resetPeople()
                    this.$refs.multipleTable.clearSelection()
                }
            }
        },
        score (newValue, oldValue) {
            this.addTable.data.forEach(o => {
                o.realScore = newValue
            })
            this.selections.forEach(o => {
                o.realScore = newValue
            })
        },
        'addTable.data': {
            immediate: true,
            deep: true,
            handler (newValue, oldValue) {
                if (this.selections.length > 0) {
                    this.selections.forEach(item => {
                        let selected = newValue.filter(
                            n => n.employeeId === item.employeeId
                        )
                        if (selected.length > 0) {
                            item.realScore = selected[0].realScore
                        }
                    })
                }
            }
        }
    },
    created () {
        this.initData()
        this.getEmployees('first')
        this.getDepts()
        this.getEmploeesNotPage()
    },
    methods: {
        // 获取员工数据 不分页
        async getEmploeesNotPage () {
            let res = await oaSvc.getEmlpoyeesList()
            this.employeeOptions = res.data
        },
        remoteMethod (query) {
            if (this.addForm.orderFrom === 0) {
                if (query !== '') {
                    this.loading = true
                    this.$axios
                        .fetch(
                            'learningServer',
                            '/learning/learning-course-list',
                            { titleLike: query }
                        )
                        .then(res => {
                            this.loading = false
                            this.options = res.data.map(item => {
                                return {
                                    value: item.id,
                                    label: item.title,
                                    courseScore: item.courseScore
                                }
                            })
                        })
                } else {
                    this.options = []
                }
            }
        },
        // 课程变化 带出积分
        handleCourseChange (val) {
            console.log(val)
            if (val.value) {
                this.score = val.courseScore
            }
        },
        handleAdd () {
            this.dialogVisible = true
        },
        async getDepts () {
            let res = await oaSvc.getAllDepts()
            this.deptOptions = res.data
        },
        initData () {
            this.$axios
                .fetch('learningServer', '/learning/learning-order-page', {
                    isAdmin: true,
                    studentId: this.searchForm.employeeId,
                    fromTitle: this.searchForm.fromTitle,
                    orderFrom: this.searchForm.orderFrom,
                    remark: this.searchForm.remark,
                    size: this.table.pageSize,
                    current: this.table.currentPage,
                    selectFrom: this.selectFrom,
                    selectTo: this.selectTo,
                    createFrom: this.createFrom,
                    createTo: this.createTo
                })
                .then(res => {
                    // console.log(res)
                    this.table.data = res.data
                    this.table.total = res.total
                })
        },
        getEmployees (first) {
            this.$axios
                .fetch('oaServer', '/oa/employee-info-page', {
                    size: this.addTable.pageSize,
                    current: this.addTable.currentPage,
                    employeeNameLike: this.employeeName,
                    groupId: this.groupId,
                    selectStatus: [0, 1]
                })
                .then(res => {
                    this.addTable.data = res.data.map(item => {
                        let obj = {}
                        obj.groupName = item.groupName
                        obj.employeeId = item.employeeId
                        obj.employeeName = item.employeeName
                        if (first) {
                            obj.realScore = this.score
                            obj.edit = false
                        }
                        return obj
                    })

                    if (this.addTable.data.length > 0) {
                        this.addTable.data.forEach(item => {
                            if (this.selections.length > 0) {
                                let selected = this.selections.filter(
                                    s => s.employeeId === item.employeeId
                                )
                                if (selected.length > 0) {
                                    this.$set(
                                        item,
                                        'realScore',
                                        selected[0].realScore
                                    )
                                    this.$set(item, 'edit', true)
                                } else {
                                    this.$set(item, 'realScore', this.score)
                                    this.$set(item, 'edit', false)
                                }
                            } else {
                                this.$set(item, 'realScore', this.score)
                                this.$set(item, 'edit', false)
                            }
                        })
                    }
                    this.addTable.total = res.total
                })
        },
        querydata () {
            this.table.currentPage = 1
            this.initData()
        },
        reset () {
            this.searchForm = {}
            this.dateRange = ['', '']
            this.createTimeRange = ['', '']
            this.table.currentPage = 1
            this.initData()
        },
        pageChange (val) {
            this.table.currentPage = val
            this.initData()
        },
        sizeChange (val) {
            this.table.currentPage = 1
            this.table.pageSize = val
            this.initData()
        },
        addTablePageChange (val) {
            this.addTable.currentPage = val
            this.getEmployees()
        },
        addTableSizeChange (val) {
            this.addTable.currentPage = 1
            this.addTable.pageSize = val
            this.getEmployees()
        },
        // 获取列表数据的唯一标识
        getRowKey (row) {
            return row.employeeId
        },
        handleSelectionChange (val) {
            // console.log(val);
            this.selections = val
            this.addTable.data.forEach(item => {
                if (
                    val.filter(v => v.employeeId === item.employeeId).length > 0
                ) {
                    item.edit = true
                    item.realScore = this.score
                } else {
                    item.edit = false
                }
            })
        },
        // 添加积分
        handeleConfirm () {
            this.$refs.addForm.validate(async valid => {
                if (valid) {
                    if (this.selections.length > 0) {
                        let param = {
                            orderFrom: this.addForm.orderFrom,
                            fromTitle: this.addForm.fromTitle.value
                                ? this.addForm.fromTitle.label
                                : this.addForm.fromTitle,
                            id: this.addForm.fromTitle.value
                                ? this.addForm.fromTitle.value
                                : null,
                            remark: this.addForm.remark,
                            orderScore: this.score,
                            scoreTime:
                                this.$moment(this.addForm.scoreTime).format(
                                    'YYYY-MM-DD'
                                ) + ' 00:00:00',
                            employees: this.selections
                        }
                        console.log(param)
                        let res = await this.$axios.fetch(
                            'learningServer',
                            '/learning/learning-order-create',
                            param,
                            'json'
                        )
                        this.$message.success('添加成功')
                        this.dialogVisible = false
                        this.initData()
                    } else {
                        this.$message.warning('请选择员工！')
                    }
                }
            })
        },
        handleEdit (row) {
            this.editForm = Object.assign({}, this.editForm, row)
            this.editFormVisible = true
        },
        // 修改提交
        async comfirmEdit () {
            this.$refs.editForm.validate(async valid => {
                if (valid) {
                    (this.editForm.scoreTime = this.$moment(
                        this.editForm.scoreTime
                    ).format('YYYY-MM-DD HH:mm:ss')),
                    await this.$axios.fetch(
                        'learningServer',
                        '/learning/learning-order-edit',
                        [this.editForm],
                        'json'
                    )
                    this.$message.success('修改成功')
                    this.editFormVisible = false
                    this.initData()
                }
            })
        },
        handleDel (row) {
            this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    await this.$axios.fetch(
                        'learningServer',
                        '/learning/learning-order-delete',
                        [row],
                        'json'
                    )
                    this.initData()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        queryPeople () {
            this.addTable.currentPage = 1
            this.getEmployees()
        },
        resetPeople () {
            this.employeeName = ''
            this.groupId = ''
            this.addTable.currentPage = 1
            this.getEmployees()
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-form.addScoreForm {
    display: flex;

    .left {
        width: 300px;

        .el-form-item {
            display: flex;
        }

        .el-form-item__content {
            flex: 1;

            .el-select,
            .el-input,
            .el-input-number {
                width: 100%;
            }
        }
    }

    .right {
        flex: 1;
        padding-left: 20px;

        .el-form {
            .el-button {
                width: 64px;
                height: 40px;
                padding: 0;
                margin: 0;
                font-size: 14px;
                line-height: 40px;
                text-align: center;
                border-radius: 8px;
            }
        }

        .el-button + .el-button {
            margin-left: 0.1rem;
        }

        .el-button.el-button--primary {
            background: #3595e1;
        }
    }
}

.editScoreDialog {
    /deep/ .el-form {
        display: block;
        width: 100%;

        .el-form-item {
            .el-select,
            .el-date-editor {
                width: 180px;
            }
        }

        .el-form-item:nth-last-child(1) {
            display: flex;
            width: 100%;

            .el-form-item__content {
                flex: 1;
                padding-right: 40px;
            }
        }
    }

    .el-row {
        width: 100%;
        line-height: 56px;

        > div {
            text-align: center;
        }
    }
}
</style>
