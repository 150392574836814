<template>
    <div>
        <el-cascader
            v-show="isCascader"
            ref="casacaderRef"
            v-model="cascaderValue"
            :options="options"
            :props="{
                checkStrictly: checkStrictly,
                label: labelFiled,
                value: valueFiled,
                expandTrigger,
                multiple,
                emitPath: false
            }"
            :show-all-levels="showAllLevels"
            collapse-tags
            filterable
            clearable
            v-bind="$attrs"
            @change="cascaderSelectChange"
        >
            <!-- <template slot-scope="{ node, data }">
            <span>{{ data.label }}</span>
            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
        </template> -->
        </el-cascader>
        <el-select
            v-show="!isCascader"
            v-model="currentItem"
            clearable
            filterable
            v-bind="$attrs"
            :multiple="multiple"
            :value-key="valueFiled"
            @change="selectChange"
        >
            <el-option
                v-for="item in options"
                :key="item[valueFiled]"
                :label="item[labelFiled]"
                :value="item"
            >
            </el-option>
        </el-select>
    </div>
</template>

<script>
import oaApi from '@/services/oa'
import utils from '@/utils'

export default {
    name: 'Department',
    components: {},
    props: {
        // 获取的数据接口函数名
        apiName: {
            type: String,
            default: 'getAllDepts'
        },
        // 是否是级联 默认是true
        isCascader: {
            type: Boolean,
            default: true
        },
        // 是否初始化给与默认值
        isInitVal: {
            type: Boolean,
            default: false
        },
        // 是否可以选择任意一级
        checkStrictly: {
            type: Boolean,
            default: true
        },
        // 值的字段名
        valueFiled: {
            type: String,
            default: 'value'
        },
        // label的字段名
        labelFiled: {
            type: String,
            default: 'label'
        },
        // 是否是级联 默认是true
        multiple: {
            type: Boolean,
            default: false
        },
        expandTrigger: {
            type: String,
            default: 'hover'
        },
        showAllLevels: {
            type: Boolean,
            default: false
        },
        disabledIds: {
            type: Array || Number,
            default: null
        }
    },
    data () {
        return {
            // value: '',
            cascaderValue: '',
            options: [],
            list: [],
            currentItem: {}
        }
    },
    watch: {
        disabledIds: {
            immediate: true,
            deep: true,
            handler (newValue, oldValue) {
                this.setDisabledItems(newValue)
            }
        },
        '$attrs.value': {
            immediate: true,
            handler (newVal) {
                if (this.isCascader && this.isInitVal) {
                    console.log(this.isCascader)
                    this.cascaderValue = newVal
                }
            }
        }
    },
    created () {
        this.getData()
        console.log(this.disabledIds)
    },
    methods: {
        async getData () {
            let res = await oaApi[this.apiName]()
            this.list = this.options = res.data
            if (this.isCascader) {
                this.setDisabledItems(this.disabledIds)
            }
        },
        valueChange (newVal) {
            if (!newVal) {
                this.currentItem = null
            } else if (
                this.currentItem == null ||
                this.currentItem[this.valueFiled] != newVal
            ) {
                let item = this.findItem(newVal)
                this.currentItem = item
            }
        },
        cascaderSelectChange (val) {
            console.log(val)
            this.currentItem = this.findItem(val)
            this.$emit('input', val)
            this.$emit('change', this.currentItem)
            this.$refs.casacaderRef.dropDownVisible = false
        },
        setCurrentItem () {
            this.currentItem = null
        },
        selectChange () {
            let item = this.currentItem
            let value = item ? item[this.valueFiled] : ''
            if (this.multiple) {
                this.$emit('input', item.map(item => item.employeeId))
            } else {
                this.$emit('input', value)
            }
            console.log(item, value)
            this.$emit('change', item)
        },
        findItem (val) {
            if (!val || !this.options || this.options.length <= 0) {
                return null
            }
            let item = this.list.find(item => item[this.valueFiled] == val)
            return item
        },
        // 级联禁止选中项设置
        setDisabledItems (needDisabledIds) {
            if (needDisabledIds) {
                this.list.forEach(item => {
                    if (needDisabledIds.indexOf(item.id) !== -1) {
                        item.disabled = true
                    } else {
                        item.disabled = false
                    }
                })
            }
            this.options = utils.makeDataToTree(
                this.options,
                'parentId',
                'id',
                'groupName'
            )
        }
    }
}
</script>
<style lang="less" scoped>
//
</style>
